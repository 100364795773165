import { createApp } from 'vue';

import App from './App.vue';
import store from './store/Index.js';
import router from './router.js';
import func from './assets/functions.js';

import Modale from './components/Modale.vue';
import SmallModale from './components/SmallModale.vue';
import AlertDisplay from './components/AlertDisplay.vue';
import FullScreenModale from './components/FullScreenModale.vue';
import MultiSelect from './components/MultiSelect.vue';
import TextEditor from './components/TextEditor.vue';
import LineChart from './components/LineChart.vue';
import Switch from './components/Switch.vue';
import SelectUx from './components/selectUx.vue';
import RadioUx from './components/RadioUx.vue';
import LoaderSpin from './components/LoaderSpin.vue';
import DraggableArea from './components/DragableArea.vue';
import CurrencyInput from './components/CurrencyInput.vue';
import PercentInput from './components/PercentInput.vue';
import FileDrop from './components/FileDrop.vue';

import ListComponent from './crm-clients/components/ListComponent.vue';
import AddButton from './crm-clients/components/AddButton.vue';
import DetentionParts from './crm-clients/components/DetentionParts.vue';
import ValueChange from './crm-clients/components/ValueChange.vue';

// Icons
import EditIcon from './assets/IconEdit.vue';
import DeleteIcon from './assets/IconDelete.vue';
import PersonIcon from './assets/IconPerson.vue';
import LocationIcon from './assets/IconLocation.vue';
import PhoneIcon from './assets/IconPhone.vue';
import EmailIcon from './assets/IconEmail.vue';

const app = createApp(App)
app.use(store)
app.use(router)

app.mixin({
    methods: {
        capitalize: func.capitalize,
        toEuro: func.toEuro,
        toPercent: func.toPercent,
        getRandomId: func.getRandomId,
        vpm: func.vpm,
        getCurrentDate: func.currentDate,
        dateToString: func.dateToString,
        nbMonth: func.nbMonth,
        getAge: func.getAge,
    }
});

app.component('modale', Modale);
app.component('small-modale', SmallModale);
app.component('draggable-area', DraggableArea);
app.component('alert-display', AlertDisplay);
app.component('full-screen-modale', FullScreenModale);
app.component('list-component', ListComponent);
app.component('add-btn', AddButton);
app.component('detention-parts', DetentionParts);
app.component('value-update', ValueChange);
app.component('multi-select', MultiSelect);
app.component('select-ux', SelectUx);
app.component('radio-ux', RadioUx);
app.component('text-editor', TextEditor);
app.component('line-chart', LineChart);
app.component('switch-ux', Switch);
app.component('loader-spin', LoaderSpin);
app.component('currency-input', CurrencyInput);
app.component('percent-input', PercentInput);
app.component('file-drop', FileDrop);

app.component('edit-icon', EditIcon);
app.component('delete-icon', DeleteIcon);
app.component('person-icon', PersonIcon);
app.component('email-icon', EmailIcon);
app.component('phone-icon', PhoneIcon);
app.component('location-icon', LocationIcon);

app.mount('#app');