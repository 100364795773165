<template>
    <form class="add-form">
        <div class="form-section">
            <label for="value">Label</label>
            <input id="value" type="text" v-model="body.label"/>
        </div>

        <div class="form-section">
            <label for="category">Catégorie</label>
            <select id="category" v-model="body.category">
                <option v-for="(value, key) in $store.getters.propertyCategories" :key="key" :value="key">{{ value }}</option>
            </select>
        </div>

        <div class="form-section">
            <label for="type">Type de bien</label>
            <select id="type" v-model="body.type">
                <option v-for="(value, key) in $store.getters.propertyTypes[body.category]" :key="key" :value="key">{{ value }}</option>
            </select>
        </div>

        <div class="form-section">
            <label for="type">Locataire</label>
            <select id="type" v-model="body.tenant_company">
                <option :value="undefined">Non-renseigné</option>
                <option v-for="society in $store.getters.getAllSocieties" :key="society._id" :value="society._id">{{ $store.getters.societyTypes[society.type] }} {{ society.label }} - {{ toEuro($store.getters.getSocietyValue(society._id)) }}</option>
            </select>
        </div>

        <div class="form-section" v-if="body.category == 'yield'">
            <label for="value">Location meublée</label>
            <input id="value" type="checkbox" v-model="body.is_furnished"/>
        </div>

        <div class="form-section">
            <label for="value">Valeur du bien</label>
            <currency-input v-model="body.value" :disabled="!isNew"/>
        </div>

        <div class="form-section" v-if="!isNew">
            <label for="value">Retrait ou versement pour la valeur</label>
            <value-update @valueUpdate="updateValue"/>
        </div>

        <div class="form-section">
            <label>Coût d'acquisition</label>
            <currency-input v-model="body.total_payment"/>
        </div>

        <div class="form-section">
            <label for="date">Date d'achat</label>
            <input id="date" type="date" v-model="body.opening_date"/>
        </div>

        <div class="form-section" v-if="body.type == 'scpi'">
            <label>Versement mensuel</label>
            <currency-input v-model="body.payment_monthly"/>
        </div>

        <div class="form-section" v-if="body.type == 'scpi'">
            <label for="value">Frais d'entrée</label>
            <input id="value" type="number" v-model="body.entry_fees"/>
        </div>

        <div class="form-section">
            <label for="detention">Indivision / détention complexe</label>
            <input type="checkbox" v-model="body.isIndivision">
        </div>

        <div class="form-section" v-if="!body.isIndivision">
            <label for="owner">Propriétaire</label>
            <select id="owner" v-model="owner">
                <option v-for="(value, index) in $store.getters.findOwners" :key="index" :value="value">{{ $store.getters.findOwnerName(value) }}</option>
            </select>
        </div>

        <div class="form-section" v-if="!body.isIndivision">
            <label for="rights">Droits</label>
            <select id="rights" v-model="right" :disabled="!isOriginalScenario && !isNew">
                <option v-for="(value, key) in $store.getters.rights" :key="key" :value="key">{{ value }}</option>
            </select>
        </div>

        <div class="form-section" v-if="!body.isIndivision && right=='np'">
            <label for="owner">Démembrement à durée fixe ?</label>
            <select id="owner" v-model="body.isfixedTermDismemberment">
                <option :value="true">OUI</option>
                <option :value="false">NON</option>
            </select>
        </div>

        <div class="form-section" v-if="!body.isIndivision && right=='np' && body.isfixedTermDismemberment">
            <label for="owner">Durée du démembrement</label>
            <input type="number" v-model="body.dismembermentDuration">
        </div>

        <div class="form-section" v-if="!body.isIndivision && right=='np' && body.isfixedTermDismemberment">
            <label for="owner">Valeur de la nue-propriété</label>
            <input type="number" v-model="body.barePropertyKey">
        </div>

        <detention-parts :detention="body.detention" @updated="updateDetention" v-if="body.isIndivision"/>

        <div class="btn-submit grow-effect" @click="submit">Enregistrer</div>
        <p class="errorMsg">{{ errorMsg }}</p>
    </form>
</template>

<script>
export default {
    props: ['import'],
    data() {
        return {
            isNew: true,
            owner: undefined,
            right: 'pp',
            errorMsg: "",
            value_update: 0,
            body: {
                _id: undefined,
                clientId: undefined,
                scenarioId: undefined,
                label: undefined,
                category: "residential",
                type: undefined,
                tenant_company: undefined,
                is_furnished: false,
                value: 0,
                isIndivision: false,
                detention: [],
                opening_date: "",
                total_payment: 0,
                payment_monthly: 0,
                entry_fees : 0.1,
                isfixedTermDismemberment: false,
                dismembermentDuration: 10,
                barePropertyKey: 0.71,
                isIpCreation: false,
            },
        }
    },
    watch: {
        owner() {
            this.body.detention = []

            if (this.owner == "commun") {
                this.body.detention.push({ owner_id : this.$store.getters.userId, right : this.right, part : 0.5 })
                this.body.detention.push({ owner_id : this.$store.getters.spouseId, right : this.right, part : 0.5 })
            } else {
                this.body.detention.push({ owner_id : this.owner, right : this.right, part : 1 })
            }
        },
        right() {
            let new_detention = []
            this.body.detention.forEach(part => {
                new_detention.push({
                    owner_id: part.owner_id,
                    right: this.right,
                    part: part.part
                })
            })

            this.body.detention = new_detention
        },
        'body.dismembermentDuration': {
            handler() {
                // Barème de Corum Eurion
                if (this.body.dismembermentDuration <= 3) {
                    this.body.dismembermentDuration = 3
                    this.body.barePropertyKey = 0.87
                } else if (this.body.dismembermentDuration == 4) {
                    this.body.barePropertyKey = 0.84
                } else if (this.body.dismembermentDuration == 5) {
                    this.body.barePropertyKey = 0.80
                } else if (this.body.dismembermentDuration == 6) {
                    this.body.barePropertyKey = 0.78
                } else if (this.body.dismembermentDuration == 7) {
                    this.body.barePropertyKey = 0.76
                } else if (this.body.dismembermentDuration == 8) {
                    this.body.barePropertyKey = 0.75
                } else if (this.body.dismembermentDuration == 9) {
                    this.body.barePropertyKey = 0.73
                } else if (this.body.dismembermentDuration == 10) {
                    this.body.barePropertyKey = 0.71
                } else if (this.body.dismembermentDuration == 11) {
                    this.body.barePropertyKey = 0.69
                } else if (this.body.dismembermentDuration == 12) {
                    this.body.barePropertyKey = 0.67
                } else if (this.body.dismembermentDuration == 13) {
                    this.body.barePropertyKey = 0.66
                } else if (this.body.dismembermentDuration == 14) {
                    this.body.barePropertyKey = 0.65
                } else if (this.body.dismembermentDuration == 15) {
                    this.body.barePropertyKey = 0.64
                } else if (this.body.dismembermentDuration == 16) {
                    this.body.barePropertyKey = 0.63
                } else if (this.body.dismembermentDuration == 17) {
                    this.body.barePropertyKey = 0.62
                } else if (this.body.dismembermentDuration == 18) {
                    this.body.barePropertyKey = 0.61
                } else if (this.body.dismembermentDuration == 19) {
                    this.body.barePropertyKey = 0.60
                } else if (this.body.dismembermentDuration == 20) {
                    this.body.barePropertyKey = 0.59
                } else if (this.body.dismembermentDuration > 20) {
                    this.body.dismembermentDuration = 20
                    this.body.barePropertyKey = 0.59
                }
            }
        }
    },
    computed: {
        isOriginalScenario() {
            const routeArray = this.$route.fullPath.split('/')

            if (routeArray[3] == 'profile' || this.$route.query.isOriginal) {
                return true
            } else {
                return false
            }
        },
    },
    methods: {
        updateValue(value) {
            this.value_update = value
        },
        updateDetention(detention) {
            this.body.detention = detention
        },
        submit() {
            if (this.isOriginalScenario) {
                this.body.isIpCreation = false
            } else if (this.isNew) {
                this.body.isIpCreation = true
            }

            if (this.body.type == undefined) {
                this.errorMsg = "Précisez le type de bien"
                return;

            } else if (this.body.value == 0) {
                this.errorMsg = "Renseignez une valeur supérieure à 0€"
                return;

            } else if (this.body.detention.length < 1) {
                this.errorMsg = "Précisez au moins un propriétaire"
                return;
            } else {
                this.errorMsg = ""

                if (this.isNew) {
                    this.body.addToStore = true
                    this.$store.dispatch('create_property', this.body)

                } else {
                    this.body.value += this.value_update
                    this.body.total_payment += this.value_update
                    this.$store.dispatch('action_modify_property', this.body)
                }

                this.$emit('closeModale')
            }
        },
    },
    mounted() {

        if (this.import) {
            this.isNew = false
            this.body = JSON.parse(JSON.stringify(this.import))
            this.body.opening_date = this.body.opening_date?.slice(0,10)

            if (!this.body.isIndivision) {
                if (this.body.detention.length > 1) {
                    this.owner = 'commun'
                } else {
                    this.owner = this.body.detention[0].owner_id
                }

                if (this.body.detention[0].right == 'pp') {
                    this.right = 'pp'
                } else if (this.body.detention[0].right == 'np') {
                    this.right = 'np'
                } else if (this.body.detention[0].right == 'us') {
                    this.right = 'us'
                }
            }
        }

        if (this.isNew) {
            this.body.clientId = this.$route.params.clientId
            this.body.scenarioId = this.$route.params.scenarioId
        }
    },
}
</script>

<style src="./style.css" scoped></style>