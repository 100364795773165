<template>
    <div class="legacy">
        <form class="legacy-params">
            <label for="">Date de décès</label>
            <input type="date" v-model="death_date">
        </form>

        <form class="legacy-params" v-if="!isOriginal">
            <div>
                <label for="order">Ordre de succession avant optimisation</label>
                <select id="order" v-model="order">
                    <option :value="1">{{ $store.getters.userName }} puis {{ $store.getters.spouseName }}</option>
                    <option :value="2">{{ $store.getters.spouseName }} puis {{ $store.getters.userName }}</option>
                </select>
            </div>

            <div v-if="marital_contract_before.situation == 'married'">
                <label for="spouse-choice" >Choix du conjoint avant optimisation</label>
                <select id="spouse-choice" v-model="spouseChoiceBefore">
                    <option v-for="(value, key) in spouseChoicesBefore" :key="key" :value="key">{{ value }}</option>
                </select>
            </div>
        </form>

        <form class="legacy-params">
            <div>
                <label for="order">Ordre de succession <span v-if="!isOriginal">après optimisation</span></label>
                <select id="order" v-model="order">
                    <option :value="1">{{ $store.getters.userName }} puis {{ $store.getters.spouseName }}</option>
                    <option :value="2">{{ $store.getters.spouseName }} puis {{ $store.getters.userName }}</option>
                </select>
            </div>
            
            <div>
                <label for="spouse-choice" v-if="$store.getters.allowSpouseChoice">Choix du conjoint <span v-if="!isOriginal">après optimisation</span></label>
                <select id="spouse-choice" v-if="$store.getters.allowSpouseChoice" v-model="spouseChoice">
                    <option v-for="(value, key) in $store.getters.spouseChoices(order)" :key="key" :value="key">{{ value }}</option>
                </select>
            </div>
        </form>
        
        <div v-if="errors.length == 0">
            <table class="legacy-detail" v-for="(death, index) in death_list" :key="index">
                <thead>
                    <th class="th-title">Décès {{ index + 1 }}</th>
                    <th>Relation</th>
                    <th>Donations reçues</th>
                    <th>Héritage brut</th>
                    <th>Capitaux décès bruts</th>
                    <th>DMTG succession</th>
                    <th>Prélèvements assurance</th>
                    <th>Total net reçu au décès</th>
                </thead>

                <tr v-for="(inheritor, index) in death" :key="index">
                    <td>{{ inheritor.full_name }}</td>
                    <td class="center-content">{{ translateRole(inheritor.role) }}</td>
                    <td class="center-content">{{ toEuro(inheritor.donation_received) }}</td>
                    <td class="center-content">{{ toEuro(inheritor.inheritance_value) }}</td>
                    <td class="center-content">{{ toEuro(inheritor.insurance_received) }}</td>
                    <td class="center-content">{{ toEuro(inheritor.inheritance_tax) }}</td>
                    <td class="center-content">{{ toEuro(inheritor.insurance_tax) }}</td>
                    <td class="center-content">{{ toEuro( ( inheritor.inheritance_value + inheritor.insurance_received ) - ( inheritor.inheritance_tax + inheritor.insurance_tax ) ) }}</td>
                </tr>
            </table>

            <table class="legacy-stats">
                <thead>
                    <th>Total net transmis</th>
                    <th>Total de l'impôt à payer</th>
                    <th>Frais de notaire</th>
                </thead>

                <tr>
                    <td class="center-content">{{ toEuro(legacy.total_transmitted) }}</td>
                    <td class="center-content">{{ toEuro(legacy.inheritance_cost) }}</td>
                    <td class="center-content">{{ toEuro(legacy.total_notary_cost) }}</td>
                </tr>
            </table>

            <ul>
                <li v-for="(msg, index) in legacy.messages" :key="index">{{ msg }}</li>
            </ul>
        </div>
        
        <div v-else>
            <h2>Erreur dans les données envoyées : </h2>
            <ol>
                <li v-for="(err, index) in errors" :key="index">{{ err }} </li>
            </ol>
        </div>
        
        <div class="btn-detail" @click="download_detail">Rapport de simulation</div>
        
        <!-- {{ body }}

        <p>Resultat</p>

        {{ legacy }} -->
    </div>
</template>

<script>
import axios from 'axios'

export default {
    props: ['isOriginal'],
    data() {
        return {
            legacyId: undefined,
            death_date: undefined,
            marital_contract_before: {},
            spouseChoiceBefore: '1/4PP',
            orderBefore: 1,
            spouseChoice: '1/4PP',
            order: 1,
            legacy: {},
            errors: [],
            body:{},
            initiated: false, // Les paramètres de simulations ont bien été récupérés avant de lancer les simulations
        }
    },
    methods: {
        async getMaritalContractBefore() {
            const originalScenarioRes = await axios.get(this.$store.getters.get_api_url + 'clients/scenario-original/' + this.$route.params.clientId, {
                headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
            })
            const scenarioOriginal = originalScenarioRes.data

            const maritalContractRes = await axios.get(this.$store.getters.get_api_url + 'clients/marital-contract/' + scenarioOriginal._id, {
                headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
            })

            this.marital_contract_before = maritalContractRes.data
        },
        async computeLegacy() {
            if (this.initiated) {
                try {
                    this.saveLegacyParams();

                    const body = {
                        datas: this.$store.getters.legacyDatas(this.order, false),
                        spouseChoice: this.spouseChoice,
                        death_date: this.death_date,
                    }

                    this.body = body;
                    const legacy = await axios.post(this.$store.getters.get_api_url + 'simulators/legacy', body, {
                        headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
                    });
                    
                    this.legacy = legacy.data;
                    this.errors = [];

                } catch(err) {
                    this.errors = err.response.data.errors;
                    this.legacy = {};
                }
            }
        },
        translateRole(role) {
            const ROLES = {
                spouse: 'Conjoint',
                child: 'Enfant',
                grandchild: 'Petit-enfant',
                parent: 'Parent',
                sibling: 'Frère/soeur',
                nephew: 'Neveu/Nièce',
                other: 'Tiers',
            }

            let result = undefined

            if (role == 'spouse') {
                const contract = this.$store.getters.getContract
                if (contract.situation == 'married') {
                    result = 'Conjoint'
                } else if (contract.situation == 'pacs') {
                    result = 'Partenaire de PACS'
                } else {
                    result = 'Concubin'
                }

            } else {
                result = ROLES[role]
            }
            

            return result
        },
        async download_detail() {
            if (this.initiated) {
                try {
                    const pdf_res = await axios.post(
                        this.$store.getters.get_api_url + 'pdf-generator/legacy-report/', 
                        this.body, 
                        {
                            headers: { 
                                authorization: 'Bearer ' + this.$store.getters.get_token 
                            },
                            responseType: 'arraybuffer',
                        }
                    );

                    if (pdf_res.status == 201) {
                        const doc = await axios.get(this.$store.getters.get_api_url + 'documents/simulation_reports/legacy.pdf', { 
                            responseType: 'blob',
                            headers: {
                                authorization : 'Bearer ' + this.$store.getters.get_token
                            }
                        })

                        const blob = new Blob([doc.data], { type: 'application/pdf' })
                        const link = document.createElement('a')
                        link.href = URL.createObjectURL(blob)
                        link.download = 'Rapport.pdf'
                        link.click()
                        URL.revokeObjectURL(link.href)
                    }

                } catch (err) {
                    alert('Erreur lors du téléchargement du PDF : ' + err.message);
                }
            }
        },
        async saveLegacyParams() {
            const body = {
                _id            : this.legacyId, 
                clientId       : this.$route.params.clientId,
                scenarioId     : this.$route.params.scenarioId,
                date           : this.death_date,
                order_before   : this.orderBefore,
                choice_before  : this.spouseChoiceBefore,
                order_after    : this.order,
                choice_after   : this.spouseChoice,
            }
            
            
            const res = await axios.post(this.$store.getters.get_api_url + 'clients/legacy-simulation', body, {
                headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
            })
            if (typeof res.data == 'string') {
                this.legacyId = res.data
            }
        },
    },
    watch: {
        spouseChoice() {
            this.computeLegacy()
        },
        order() {
            this.orderBefore = this.order
            this.computeLegacy()
        },
        spouseChoiceBefore() {
            this.saveLegacyParams()
        },
        orderBefore() {
            this.saveLegacyParams()
        },
        death_date() {
            this.computeLegacy()
        },
        '$store.state': {
            deep: true,
            handler() {
                this.computeLegacy()
            }
        },
    },
    computed: {
        spouseChoicesBefore() {
            let has_ddv = undefined

            if (this.orderBefore == 1)
                has_ddv = this.marital_contract_before.ddv_userforspouse
            else if (this.orderBefore == 2)
                has_ddv = this.marital_contract_before.ddv_spouseforuser

            if (has_ddv) {
                return {
                    '100%US': "Tout l'usufruit",
                    'QDPP': "La quotité disponible en pleine propriété",
                    '1/4PP': '1/4 en pleine propriété',
                    '3/4US+1/4PP': "1/4 en pleine propriété et 3/4 de l'usufruit",
                    'O%': 'Renonciation',
                }
            } else {
                return {
                    '100%US': "Tout l'usufruit",
                    '1/4PP': '1/4 en pleine propriété',
                    'O%': 'Renonciation',
                }
            }
        },
        death_list() {
            let result = []

            if (this.legacy.first_death_result) {
                result.push(this.legacy.first_death_result.formated_inheritors)
            }

            if (this.legacy.second_death_result) {
                result.push(this.legacy.second_death_result.formated_inheritors)
            }
            
            return result
        },
    },
    async mounted() {
        const res = await axios.get(this.$store.getters.get_api_url + 'clients/legacy-simulation/' + this.$route.params.scenarioId, {
            headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
        })

        if (res.data) {
            this.legacyId = res.data._id
            this.death_date = res.data.date
            this.spouseChoiceBefore = res.data.choice_before
            this.orderBefore = res.data.order_before
            this.spouseChoice = res.data.choice_after
            this.order = res.data.order_after
        }

        this.getMaritalContractBefore()

        this.initiated = true
        this.computeLegacy()
    }
}
</script>

<style scoped>
.legacy {
    display: flex;
    flex-direction: column;
}

.legacy-params {
    width: 80%;
    margin: 20px auto;
    display: flex;
    flex-direction: column;
    text-align: center;
}

.legacy-params div {
    width: 100%;
    margin-top: 10px;
    text-align: left;
    display: flex;
    flex-wrap: wrap;
}
.legacy-params label, 
.legacy-params select, 
.legacy-params input {
    font-family: "Avenir", sans-serif;
    align-self: center;
    flex-basis: 100%;
    font-size: 18px;
}
.legacy-params label {
    font-weight: bold;
}
.legacy-params select, 
.legacy-params input {
    cursor: pointer;
    padding: 5px;
    border-radius: 5px;
    border: 1px solid lightgrey;
}

table {
    width: 95%;
    margin: 10px auto;
    border-spacing : 0;
}

thead {
    background-color: rgba(10,28,107,.6);
    color: white;
    
}
th, td {
    border: solid 0.5px #eee9e0;
}

th {
    padding: 10px;
}

.th-title {
    background-color: rgb(216, 141, 2);
}

td {
    background-color: #f7efdf;
    padding: 5px;
    color: rgb(92, 91, 91);
}
.center-content {
    text-align: center;
}

.btn-detail {
    display: inline-block;
    margin: 10px auto;
    cursor: pointer;
    text-align: center;
    padding: 20px;
    background-color: rgb(0, 0, 67);
    color: white;
    border-radius: 5px;
}
.btn-detail:hover {
    background-color: rgba(0, 0, 67, 0.559);
}
</style>