<template>
    <form class="add-form">
        
        <div class="form-section">
            <label for="role">Relation</label>
            <select id="role" v-model="body.role" :disabled="!isOriginalScenario && !isNew && body.role != 'other'">
                <option v-for="(value, key) in roleOptions" :key="key" :value="key">{{ value }}</option>
            </select>
        </div>
        
        <div class="form-section">
            <label for="civility">Civilité</label>
            <select id="civility" v-model="body.civility" :disabled="!isOriginalScenario && !isNew">
                <option v-for="(value, key) in $store.getters.civilities" :key="key" :value="key">{{ value }}</option>
            </select>
        </div>

        <div class="form-section">
            <label for="value">Prénom</label>
            <input type="text" v-model="body.firstname" :disabled="!isOriginalScenario && !isNew">
        </div>

        <div class="form-section">
            <label for="value">Nom</label>
            <input type="text" v-model="body.lastname" :disabled="!isOriginalScenario && !isNew">
        </div>

        <div class="form-section">
            <label for="value">Date de naissance</label>
            <input type="date" v-model="body.birthdate" :disabled="!isOriginalScenario && !isNew">
        </div>

        <div class="form-section" v-if="!['user', 'spouse'].includes(body.role)">
            <label for="parent">Parent</label>
            <select id="parent" v-model="parent">
                <option v-for="(value, index) in parentOptions" :key="index" :value="value">{{ $store.getters.findPersonName(value) }}</option>
            </select>
        </div>

        <div class="form-section" v-if="!isOriginalScenario && !isNew && ( originalNbParents != currentNbParent )">
            <label for="parent">Adoption</label>
            <select id="parent" v-model="body.adoption_mode">
                <option v-for="(value, key) in adoption_modes" :key="key" :value="value">{{ key }}</option>
            </select>
        </div>

        <div class="form-section">
            <label>Décédé ?</label>
            <input type="checkbox" v-model="body.is_dead">
        </div>

        <div class="form-section" v-if="body.is_dead">
            <label>Date de décès</label>
            <input type="date" v-model="body.death_date">
        </div>

        <div class="form-section">
            <label>Handicapé ?</label>
            <input type="checkbox" v-model="body.is_handicaped">
        </div>

        <div class="form-section">
            <label>Vétéran ?</label>
            <input type="checkbox" v-model="body.is_veteran">
        </div>

        <div class="form-section"  v-if="['child'].includes(body.role)">
            <label>Rattaché fiscalement ?</label>
            <input type="checkbox" v-model="body.is_attached">
        </div>

        <div class="form-section" v-if="['child'].includes(body.role) && body.is_attached">
            <label>Garde alternée</label>
            <input type="checkbox" v-model="body.joint_custody">
        </div>

        <div class="form-section"  v-if="['child'].includes(body.role) && !body.is_attached">
            <label>Vit à la maison ?</label>
            <input type="checkbox" v-model="body.lives_at_home">
        </div>

        <div class="form-section" v-if="['child'].includes(body.role) && body.is_attached">
            <label for="parent">Situation</label>
            <select id="parent" v-model="body.situation">
                <option v-for="(value, key) in situations" :key="key" :value="key">{{value}}</option>
            </select>
        </div>

        <div class="form-section" v-if="['child'].includes(body.role) && body.is_attached">
            <label>Étudiant ?</label>
            <input type="checkbox" v-model="body.is_student">
        </div>

        <div class="form-section"  v-if="['child'].includes(body.role) && body.is_student">
            <label for="parent">Niveau d'études au 31/12</label>
            <select id="parent" v-model="body.education_level">
                <option v-for="(value, key) in $store.getters.eductionLevels" :key="key" :value="key">{{ value }}</option>
            </select>
        </div>

        <div class="form-section"  v-if="['child'].includes(body.role) && body.is_attached">
            <label>Au chômage ?</label>
            <input type="checkbox" v-model="body.is_unemployed">
        </div>

        <div class="btn-submit grow-effect" @click="submit">Enregistrer</div>
        <p class="errorMsg">{{ errorMsg }}</p>
    </form>
</template>

<script>
export default {
    props: ['import', 'role'],
    data() {
        return {
            isNew: true,
            parent: undefined,
            errorMsg: "",
            body: {
                _id: undefined,
                clientId: undefined,
                scenarioId: undefined,
                role: undefined,
                civility: 'mr',
                firstname: undefined,
                lastname: undefined,
                birthdate: undefined,
                parent1: undefined,
                parent2: undefined,
                is_dead: false,
                death_date: "",
                is_handicaped: false,
                is_attached: false,
                joint_custody: false,
                lives_at_home: true,
                situation: undefined,
                is_student: false,
                education_level: undefined,
                is_veteran: false,
                is_unemployed: true,
                adoption_mode: undefined,
            },
            adoption_modes: {
                "--": undefined,
                "Adoption plenière": "full",
                "Adoption simple": "simple",
            },
            situations: {
                alone: "Célibataire",
                married: "Marié",
                pacs: "Pacsé"
            },
        }
    },
    watch: {
        parent() {
            if (this.parent == 'commun') {
                this.body.parent1 = this.$store.getters.userId,
                this.body.parent2 = this.$store.getters.spouseId

            } else if (this.parent != undefined) {
                this.body.parent1 = this.parent
                this.body.parent2 = null
            }
        }
    },
    computed: {
        isOriginalScenario() {
            const routeArray = this.$route.fullPath.split('/')

            if (routeArray[3] == 'profile' || this.$route.query.isOriginal) {
                return true
            } else {
                return false
            }
        },
        originalNbParents() {
            let nb = 1

            if (this.import) {
                this.import.parent2 == undefined ? nb = 1 : nb = 2;
            }

            return nb
        },
        currentNbParent() {
            let nb = 1

            if (this.body) {
                this.body.parent2 == undefined ? nb = 1 : nb = 2;
            }

            return nb
        },
        parentOptions() {
            if (this.isOriginalScenario || this.isNew) {
                return this.$store.getters.findParents(this.body.role)

            } else if (this.body.role == 'child') {
                // On peut changer le parent d'un enfant que si c'est pour l'adopton par le conjoint (option commun ouverte)
                if (this.import.parent2) {
                    return ['commun']
                } else {
                    return this.$store.getters.findParents(this.body.role).filter(p => ['commun', this.import.parent1].includes(p))
                }
            } else {
                return this.$store.getters.findParents(this.body.role)
            }
        },
        roleOptions() {
            if (!this.isNew && !this.isOriginalScenario && this.body.role == 'other') {
                // Adoption des tiers authorisée
                return {
                    other: 'Tiers',
                    child: 'Enfant',
                }
            } else {
                return this.$store.getters.allRoles
            }
        }
    },
    methods: {
        submit() {
            if (this.body.parent1 == undefined && !['user', 'spouse'].includes(this.body.role)) {
                this.errorMsg = "Renseignez au moins un parent"

            } else if (!this.body.firstname || !this.body.lastname) {
                this.errorMsg = "Renseignez le nom de la personne"

            } else if (!this.body.civility) {
                this.errorMsg = "Renseignez la civilité"

            } else if (!this.body.birthdate) {
                this.errorMsg = "Renseignez la date de naissance"
                
            } else if (!this.body.role) {
                this.errorMsg = "Renseignez la relation"
                
            } 
            // else if (this.originalNbParents == 1 && this.currentNbParent == 2 && !this.body.adoption_mode && !this.isNew) {
            //     this.errorMsg = "Renseignez le type d'adoption"
                
            // } else if (this.import?.adoption_mode != undefined && this.currentNbParent > 1) {
            //     this.errorMsg = "Renseignez le type d'adoption"
                
            // } 
            else {
                if (this.isNew) {
                    this.body.addToStore = true
                    this.$store.dispatch('create_person', this.body)
                } else {
                    this.$store.dispatch('action_modify_person', this.body)
                }
                this.$emit('closeModale')
            }
        },
    },
    mounted() {
        if (this.import) {
            this.isNew = false
            this.body = JSON.parse(JSON.stringify(this.import))
            this.body.birthdate = this.body.birthdate?.slice(0,10)

            if (this.body.parent1 && this.body.parent2) {
                this.parent = 'commun'
            } else {
                this.parent = this.body.parent1
            }

        } else {
            this.body.role = this.role
            this.body.clientId = this.$route.params.clientId
            this.body.scenarioId = this.$route.params.scenarioId
        }
    }
}
</script>

<style src="./style.css" scoped></style>