<template>
    <div class="goal-survey">
        <h2>Objectifs</h2>

        <div class="survey">
            <div class="form-section">
                <label>Quel est le besoin de revenus mensuels nets du foyer à la retraite ?</label>
                <currency-input v-model="income_goal"></currency-input>
            </div>

            <div class="form-section">
                <label>À quelle âge {{ $store.getters.userName }} souhaite partir à la retraite ?</label>
                <select v-model="user_retirement_age">
                    <option v-for="(value, key) in retirement_age_list" :key="key" :value="key">{{ value }}</option>
                </select>
            </div>

            <div class="form-section" v-if="$store.getters.spouseName">
                <label>À quelle âge {{ $store.getters.spouseName }} souhaite partir à la retraite ?</label>
                <select v-model="spouse_retirement_age">
                    <option v-for="(value, key) in retirement_age_list" :key="key" :value="key">{{ value }}</option>
                </select>
            </div>
        </div>

        <div class="action-btns">
            <div class="dowload-audit btn" @click="downloadDoc">Bilan thématique</div>
            <div class="call-ip btn">Faire appel à un IP</div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    data() {
        return {
            retirement_age_list: {
                min: "Le plus tôt possible",
                62: "62 ans",
                63: "63 ans",
                64: "64 ans",
                65: "65 ans",
                66: "66 ans",
                67: "67 ans",
            },
            income_goal: 7000,
            user_retirement_age: 64,
            spouse_retirement_age: 64,
        }
    },
    methods: {
        async downloadDoc() {
            this.isLoading = true

            try {
                const doc = await axios.get(this.$store.getters.get_api_url + 'documents/audit/retirement.docx', { 
                    responseType: 'blob',
                    headers: {
                        authorization : 'Bearer ' + this.$store.getters.get_token
                    }
                })
                const blob = new Blob([doc.data], { type: 'application/docx' })
                const link = document.createElement('a')
                link.href = URL.createObjectURL(blob)
                link.download = 'Bilan.docx'
                link.click()
                URL.revokeObjectURL(link.href)
            } catch (err) {
                console.log(err)
                this.isLoading = false
                alert('Erreur lors de la création du bilan automatisé')
            }

        },
    }
}
</script>

<style>
.goal-survey {
    display: flex;
    flex-direction: column;
}

.survey {
    width: 80%;
    min-width: 800px;
}

.form-section {
    display: flex;
    justify-content: space-between;
    padding: 10px;
}

.form-section label, 
.form-section input,
.form-section select {
    padding: 10px;
    font-size: 22px;
}

.form-section input,
.form-section select {
    border-radius: 10px;
    border: 1px solid lightgrey;
    flex-grow: 1;
    text-align: right;
}

.action-btns {
    display: flex;
    justify-content: center;
}

.action-btns .btn {
    cursor: pointer;
    padding: 15px;
    font-size: 22px;
    margin: 10px;
    border-radius: 10px;
    font-weight: bold;
}

.dowload-audit {
    background-color: rgb(255, 174, 23);
    color: white;
}

.call-ip {
    background-color: rgb(0, 0, 91);
    color: white;
}
</style>