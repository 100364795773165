<template>
    <div>
        <h2 class="category-title">Dons et donations</h2>

        <div class="elements-container">
            <div class="element" v-for="asset in $store.getters.getAllGifts" :key="asset._id">
                <h3>{{ $store.getters.giftTypes[asset.type] }}</h3>
                <table>
                    <tr>
                        <td class="col1">Type de donation</td>
                        <td class="col2">{{ $store.getters.giftTypes[asset.type] }}</td>
                    </tr>
                    <tr>
                        <td class="col1">Valeur civile</td>
                        <td class="col2">{{ toEuro(asset.value) }}</td>
                    </tr>
                    <tr>
                        <td class="col1">Valeur fiscale</td>
                        <td class="col2">{{ toEuro(asset.fiscal_value) }}</td>
                    </tr>
                    <tr>
                        <td class="col1">Date</td>
                        <td class="col2">{{ dateToString(asset.date) }}</td>
                    </tr>
                    <tr>
                        <td class="col1">Donateur</td>
                        <td class="col2">{{ $store.getters.findPersonName(asset.donor) }}</td>
                    </tr>
                    <tr>
                        <td class="col1">Donataire</td>
                        <td class="col2">{{ $store.getters.findPersonName(asset.donee) }}</td>
                    </tr>
                </table>

                <div class="btns-container">
                    <div class="btn btn-delete" @click="deleteItem(asset._id)">SUPPRIMER</div>
                    <div class="btn btn-modify" @click="toggleModifyModale(asset._id)">MODIFIER</div>

                    <modale :show="showModifyModale[asset._id]" @toggle="toggleModifyModale(asset._id)">
                        <Gift :import="asset" @closeModale="toggleModifyModale(asset._id)"/>
                    </modale>
                </div>
            </div>

            <div class="add-btn" @click="toggleModale">
                <div>+</div>

                <modale :show="showModale" @toggle="toggleModale">
                    <Gift @closeModale="toggleModale"/>
                </modale>
            </div>
        </div>
    </div>
</template>

<script>
import Gift from '../../../forms/Gift.vue'

export default {
    components: {Gift},
    data() {
        return {
            showModale: false,
            showModifyModale: {},
        }
    },
    methods: {
        deleteItem(id) {
            const valid = confirm('Êtes vous sur de vouloir supprimer cette donation ?')
            if (valid) {
                this.$store.dispatch('delete_gift', id)
            }
        },
        toggleModale() {
            this.showModale = !this.showModale
        },
        toggleModifyModale(id) {
            if (this.showModifyModale[id] == undefined) {
                this.showModifyModale[id] = true
            } else {
                this.showModifyModale[id] = !this.showModifyModale[id]
            }
        }
    }
}
</script>

<style src="../style.css" scoped/>