<template>
    <div>
        <h2 class="category-title">Testaments</h2>

        <div class="elements-container">
            <div class="element" v-for="asset in $store.getters.getAllWills" :key="asset._id">
                <h3>{{ $store.getters.willTypes[asset.type] }}</h3>
                <table>
                    <tr>
                        <td class="col1">Type de testament</td>
                        <td class="col2">{{ $store.getters.propertyCategories[asset.type] }}</td>
                    </tr>
                    <tr>
                        <td class="col1">Testateur</td>
                        <td class="col2">{{ $store.getters.findPersonName(asset.owner)}}</td>
                    </tr>
                </table>

                <div class="btns-container">
                    <div class="btn btn-delete" @click="deleteItem(asset._id)">SUPPRIMER</div>
                    <div class="btn btn-modify" @click="toggleModifyModale(asset._id)">MODIFIER</div>

                    <modale :show="showModifyModale[asset._id]" @toggle="toggleModifyModale(asset._id)">
                        <Will :import="asset" @closeModale="toggleModifyModale(asset._id)"/>
                    </modale>
                </div>
            </div>

            <div class="add-btn" @click="toggleModale">
                <div>+</div>

                <modale :show="showModale" @toggle="toggleModale">
                    <Will @closeModale="toggleModale"/>
                </modale>
            </div>
        </div>
    </div>
</template>

<script>
import Will from '../../../forms/Will.vue'

export default {
    components: {Will},
    data() {
        return {
            showModale: false,
            showModifyModale: {},
        }
    },
    methods: {
        deleteItem(id) {
            const valid = confirm('Êtes vous sur de vouloir supprimer ce testament ?')
            if (valid) {
                this.$store.dispatch('delete_property', id)
            }
        },
        toggleModale() {
            this.showModale = !this.showModale
        },
        toggleModifyModale(id) {
            if (this.showModifyModale[id] == undefined) {
                this.showModifyModale[id] = true
            } else {
                this.showModifyModale[id] = !this.showModifyModale[id]
            }
        }
    }
}
</script>

<style src="../style.css" scoped/>