import { createRouter, createWebHistory } from 'vue-router';
import state from './store/state.js';

import AuthPage from './auth/AuthPage.vue';
import AdminCreation from './auth/AdminCreation.vue';

import ClientIndex from './crm-clients/Index.vue';
    import ClientList from './crm-clients/pages/ClientsArray.vue';
    import ClientView from './crm-clients/pages/View.vue';

    import ClientProfileIndex from './crm-clients/pages/profile/Index.vue';
        import clientProfileCouple from './crm-clients/pages/profile/family/Couple.vue';
        import clientProfileChildren from './crm-clients/pages/profile/family/Children.vue';
        import clientProfileGrandchildren from './crm-clients/pages/profile/family/Grandchildren.vue';
        import clientProfileOthers from './crm-clients/pages/profile/family/Others.vue';
        import clientProfileSocieties from './crm-clients/pages/profile/wealth/Societies.vue';
        import clientProfileProperties from './crm-clients/pages/profile/wealth/Properties.vue';
        import clientProfileAssets from './crm-clients/pages/profile/wealth/Assets.vue';
        import clientProfileMovables from './crm-clients/pages/profile/wealth/Movables.vue';
        import clientProfileInsurances from './crm-clients/pages/profile/wealth/Insurances.vue';
        import clientProfileDebts from './crm-clients/pages/profile/wealth/Debts.vue';
        import clientProfileGifts from './crm-clients/pages/profile/dispositions/Gifts.vue';
        import clientProfileWills from './crm-clients/pages/profile/dispositions/Wills.vue';
        import clientProfileIncomes from './crm-clients/pages/profile/incomes/Incomes.vue';
        import clientProfileTaxDeductions from './crm-clients/pages/profile/incomes/TaxDeductions.vue';
        import clientProfileTaxReductions from './crm-clients/pages/profile/incomes/TaxReductions.vue';
        import clientProfileCareer from './crm-clients/pages/profile/career/Career.vue';
        import ClientProfileLegacySimulation from './crm-clients/pages/profile/simulations/Legacy.vue';
        import ClientProfileIfiSimulation from './crm-clients/pages/profile/simulations/Ifi.vue';
        import ClientProfileIrSimulation from './crm-clients/pages/profile/simulations/IncomeTaxes.vue';
        import ClientProfileRetirementSimulation from './crm-clients/pages/profile/simulations/retirement/Retirement.vue';
            import ClientProfileRetirementSurvey from './crm-clients/pages/profile/simulations/retirement/adviceCareerPath/GoalSurvey.vue';

        import ClientProfileKyc from './crm-clients/pages/profile/adviceCareerPath/goal_survey/Index.vue';
        import ClientProfileRecommendation from './crm-clients/pages/profile/adviceCareerPath/simplified_recommendation/Index.vue';

    import AdvisorToolIndex from './crm-clients/pages/profile/adviceCareerPath/simplified_recommendation/advisor_tool/Index.vue';
    
    import AuditPage from './crm-clients/pages/scenario/audit/index.vue';
    import ScenarioGlobalIndex from './crm-clients/pages/scenario/GlobalIndex.vue';
        import IndexScenario       from './crm-clients/pages/scenario/simulation/IndexScenario.vue';
        import OverviewScenario    from './crm-clients/pages/scenario/simulation/scenarioOverview.vue';
        import FamilyPage          from './crm-clients/pages/scenario/simulation/pages/Family.vue';
        import WealthPage          from './crm-clients/pages/scenario/simulation/pages/Wealth.vue';
        import DispositionsPage    from './crm-clients/pages/scenario/simulation/pages/Dispositions.vue';
        import TaxesPage           from './crm-clients/pages/scenario/simulation/pages/Taxes.vue';
    import SolutionsPage       from './crm-clients/pages/scenario/solutions/Index.vue';
    import DocumentGenerator   from './crm-clients/pages/scenario/document/index.vue';

import IndexCrm             from './crm-advisors/Index.vue';
    import CrmAdvisorList       from './crm-advisors/clients/List.vue';
    import CrmAdvisorPage       from './crm-advisors/clients/Client.vue';
    import CrmAdvisorReporting  from './crm-advisors/reporting/Index.vue';

import IndexLibrary from './Library/Index.vue';
    import HomeLibrary from './Library/pages/Home.vue';
    import FileLibrary from './Library/pages/File.vue';
    import AdminLibrary from './Library/pages/Admin.vue';

import IndexRhManagement from './rh-management/Index.vue';

import IndexCalculators from './calculators/Index.vue';
    import ListCalculators from './calculators/CalculatorsList.vue';
    import PropertySellCalculators from './calculators/CalculatorsPages/RealEstateGains.vue';
    import LegacyCalculators from './calculators/CalculatorsPages/LegacySimplified.vue';
    import LoanRefundCalculators from './calculators/CalculatorsPages/LoanRefund.vue';
    import DonationTaxesCalculators from './calculators/CalculatorsPages/DonationTax.vue';
    import IncomeTaxesCalculators from './calculators/CalculatorsPages/IncomeTax.vue';
    import SocialChargesCalculators from './calculators/CalculatorsPages/SocialContributions.vue';
    import ManagerRemunerationCalculators from './calculators/CalculatorsPages/ManagerRemuneration.vue';
    import IfiCalculators from './calculators/CalculatorsPages/IfiTax.vue';
    import SocietySellCalculators from './calculators/CalculatorsPages/SocietySell.vue';
    import NotaryFeesCalculators from './calculators/CalculatorsPages/NotaryFees.vue';
    import RisAnalysisCalculators from './calculators/CalculatorsPages/RisAnalysis.vue';

import IndexReferential from './referential/Index.vue';
    import SolutionsReferential from './referential/pages/Solutions.vue';
    import RemindersReferential from './referential/pages/Reminders.vue';
    import ReminderSectionReferential from './referential/pages/ReminderSections.vue';
    import SolutionSectionReferential from './referential/pages/SolutionSections.vue';
    import ConclusionSectionReferential from './referential/pages/ConclusionSections.vue';

const router = createRouter({
    history: createWebHistory(),
    routes: [
        { path: '/', redirect: '/client' },
        { path: '/connexion', component: AuthPage },
        { path: '/administrateurs', component: AdminCreation },
        { path: '/client', component: ClientIndex, children: [
            { path: '', component: ClientList },
            { path: ':clientId', component: ClientIndex, children: [
                { path: 'view', component: ClientView },
                { path: 'profile/:scenarioId', component: ClientProfileIndex, children: [
                    { path: 'couple', component: clientProfileCouple },
                    { path: 'children', component: clientProfileChildren },
                    { path: 'grandchildren', component: clientProfileGrandchildren },
                    { path: 'others', component: clientProfileOthers },
                    { path: 'societies', component: clientProfileSocieties },
                    { path: 'properties', component: clientProfileProperties },
                    { path: 'assets', component: clientProfileAssets },
                    { path: 'movables', component: clientProfileMovables },
                    { path: 'insurances', component: clientProfileInsurances },
                    { path: 'debts', component: clientProfileDebts },
                    { path: 'gifts', component: clientProfileGifts },
                    { path: 'wills', component: clientProfileWills },
                    { path: 'incomes', component: clientProfileIncomes },
                    { path: 'tax-deductions', component: clientProfileTaxDeductions },
                    { path: 'tax-reductions', component: clientProfileTaxReductions },
                    { path: 'career-user', component: clientProfileCareer },
                    { path: 'career-spouse', component: clientProfileCareer },
                    { path: 'legacy-simulation', component: ClientProfileLegacySimulation },
                    { path: 'ifi-simulation', component: ClientProfileIfiSimulation },
                    { path: 'ir-simulation', component: ClientProfileIrSimulation },
                    { path: 'retirement-simulation', component: ClientProfileRetirementSimulation },
                    { path: 'retirement-simulation/ClientProfileKyc', component: ClientProfileRetirementSurvey},
                    { path: 'ClientProfileKyc', component: ClientProfileKyc},
                    { path: 'recommendation', component: ClientProfileRecommendation, children: [

                    ]},
                ] },
                { path: 'advisor-tool/:scenarioId', component: AdvisorToolIndex },
                { path: 'recommendation/:recommendationId', component: ScenarioGlobalIndex, children: [
                    { path: 'overview', component: OverviewScenario },
                    { path: 'audit', component: AuditPage },
                    { path: 'scenario/:scenarioId', component: IndexScenario, children: [
                        { path: 'family', component: FamilyPage },
                        { path: 'wealth', component: WealthPage},
                        { path: 'dispositions', component: DispositionsPage},
                        { path: 'taxes', component: TaxesPage},
                    ] },
                    { path: 'solutions', component: SolutionsPage },
                    { path: 'recommendation', component: DocumentGenerator },
                    
                ] },
            ] },
        ] },
        { path: '/conseillers', component: IndexCrm, children: [
            { path: 'crm', component: CrmAdvisorList },
            { path: 'crm/:id', component: CrmAdvisorPage },
            { path: 'reporting', component: CrmAdvisorReporting },
        ] }, 
        { path: '/librairie', component: IndexLibrary, children: [
            { path: 'home', component: HomeLibrary, alias: "" },
            { path: 'home/:fileId', component: FileLibrary },
            { path: 'administration', component: AdminLibrary }
        ] }, 
        { path: '/gestion', component: IndexRhManagement },
        { path: '/calculettes', component: IndexCalculators, children: [
            { path: '', component: ListCalculators },
            { path: 'plus-value-immobiliere-particuliers', component: PropertySellCalculators },
            { path: 'succession-simplifiee', component: LegacyCalculators },
            { path: 'mensualite-emprunt', component: LoanRefundCalculators },
            { path: 'dmtg-donation', component: DonationTaxesCalculators },
            { path: 'impot-fortune-immobiliere', component: IfiCalculators },
            { path: 'impot-sur-le-revenu', component: IncomeTaxesCalculators },
            { path: 'pv-cession-societe', component: SocietySellCalculators },
            { path: 'social-charges', component: SocialChargesCalculators },
            { path: 'manager-remuneration', component: ManagerRemunerationCalculators },
            { path: 'ris-analysis', component: RisAnalysisCalculators },
            { path: 'notary-fees', component: NotaryFeesCalculators },
        ] },
        { path: '/referential', component: IndexReferential, children: [
            { path: 'solutions', component: SolutionsReferential, alias: "" },
            { path: 'reminders', component: RemindersReferential },
            { path: 'reminder-section', component: ReminderSectionReferential },
            { path: 'solution-section', component: SolutionSectionReferential },
            { path: 'conclusion-section', component: ConclusionSectionReferential },
        ] }, 
        { path: '/:notFound(.*)', redirect: '/client' }
    ]
});

router.beforeEach((to, _from, next) => {
    if (to.fullPath != '/connexion') {
        if (localStorage.getItem('token') == undefined) {
            next('/connexion')
        } else if (to.fullPath == '/administrateurs' && !['president', 'director'].includes(state.admin.position)) {
            next('/client')
        } else {
            next()
        }
    } else {
        if (localStorage.getItem('token')) {
            next('/client')
        } else {
            next()
        }
    }
})

export default router;